import React from "react";
import "../Home/Home.css";
import title from "../../assets/webpimages/title_with_studio_name.webp";
// import Preview2 from "../../assets/webpimages/preview 2.webp";
import Pre1 from "../../assets/webpimages/img 1.png";
import Pre3 from "../../assets/webpimages/preview 3.webp";
import Pre4 from "../../assets/webpimages/preview 4.webp";
import Preview1 from "../../assets/webpimages/preview 1.webp";
// import Comic from "../../assets/webpimages/Comic cover.webp";
import queensland from "../../assets/webpimages/studio logo.webp";
import v2 from "../../assets/webpimages/Endwars 2.png";
import v3 from "../../assets/webpimages/Endwars 1 ENG.png";
import v4 from "../../assets/webpimages/Endwars 1 TAM.png";
import v5 from "../../assets/webpimages/set.png";
import v6 from "../../assets/webpimages/Endwars 3.png";
import v7 from "../../assets/webpimages/combo 3.png";
import scrollimg from "../../assets/webpimages/parallax bnr@2x.webp";
import scroll from "../../assets/webpimages/Scroll up.webp";
import character1 from "../../assets/webpimages/angelo.webp";
import character2 from "../../assets/webpimages/diana.webp";
import character3 from "../../assets/webpimages/commander.webp";
import character4 from "../../assets/webpimages/magoth.webp";
// import character5 from "../../assets/webpimages/gol.webp";
// import character6 from "../../assets/webpimages/Demon2.webp";
// import character7 from "../../assets/webpimages/gda.webp";
import insta from "../../assets/webpimages/Instagram_logo.png";
import fb from "../../assets/webpimages/facebook.png";
import x from "../../assets/webpimages/x.png";

export const Home = () => {

  // const [navActive, setNavActive] = useState("Home");
  // const NavActive = (e) => {
  //   setNavActive(e.target.id);
  // };

  return (
    <>
      <nav className="navbar navbar-expand-md sticky-top d-md-block d-none" id="bg-nav">
        <div className="container">
          <div
            className="collapse navbar-collapse justify-content-center"
            id="navbarNav"
          >
            <ul className="navbar-nav mb-2 mb-md-0">
              <li className="nav-item">
                <a className="nav-link px-md-3 px-lg-4" href="#hero">
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link px-md-3 px-lg-4" href="#about">
                  About
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link px-md-3 px-lg-4" href="#lead">
                  Characters
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link px-md-3 px-lg-4" href="#preview">
                  Preview
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#author">
                  The Author
                </a>
              </li>
            </ul>
          </div>
          <a
            href="
            https://www.facebook.com/profile.php?id=100089729911446"
            target={"_blank"}
          >
            <img src={fb} className="me-2" alt="ddd" />
          </a>
          <a href="https://twitter.com/Endwars_comics" target={"_blank"} rel="noreferrer">
            <img src={x} className="me-2" alt="ddd" />
          </a>
          <a href="https://www.instagram.com/endwars_comics/" target={"_blank"} rel="noreferrer">
            <img src={insta} alt="ddd" />
          </a>
        </div>
      </nav>{" "}

      <div className="d-flex d-block d-md-none">
        <div className="col-12">
          <div className="float-end me-4 mt-2">
            <a
              href="
              https://www.facebook.com/profile.php?id=100089729911446"
              target={"_blank"}
            >
              <img src={fb} className="me-2" alt="ddd" />
            </a>
            <a href="https://twitter.com/Endwars_comics" target={"_blank"} rel="noreferrer">
              <img src={x} className="me-2" alt="ddd" />
            </a>
            <a
              href="https://www.instagram.com/endwars_comics/"
              target={"_blank"}
              rel="noreferrer"
            >
              <img src={insta} alt="ddd" />
            </a>
          </div>
        </div>
      </div>

      <section
        id="hero"
        className="min-vh-100 d-flex align-items-center text-center"
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <img src={title} alt="icons" />
              <br />
              <div className="row mt5 mt-md-0">
                <div className="col-12">
                  <a
                    href="https://forms.gle/rniwRW9ZtwHkgiix7"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <button className="buybtn mt-5 mt-md-0 me-2">
                      SUBMIT FEEDBACK
                    </button>
                  </a>
                  <a
                    href="https://forms.gle/Q41pSvcAo2tSUwmS6"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <button className="buybtn mt-3 mt-md-0">ORDER NOW</button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="about" className="section-padding">

        {/* <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <div className="section-title">
                <h1 className="display-2 fw-bolder section-title-text">
                  LOREM IPSUM DOLOR SIT AMET CONSETETUR
                </h1>
                <p>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam et
                  justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                  sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                  ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                  nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos.
                </p>
              </div>
            </div>
          </div>
        </div> */}

        <div className="container">
          <div className="row">
            <div className="col-md-10 col-xl-8 col-12 mx-auto">
              <p className="chartitles text-center pt-2">ABOUT THE BOOK</p>
              <p className="bigcontent text-center pt-2">
                A STORY OF
                <br /> HUMANITY
                <br /> AND WAR
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-9 col-xl-6 col-12 mx-auto">
              <p className="normalcontent">
                What happens when a young boy thinks this physical and material
                world is all that we live for, denying all the signs that the
                Creator has sent to live the full potential of his life? Will he
                realise and accept his call even if he doesn’t believe in the
                supernatural?.
              </p>
              <p className="normalcontent">
                Endwars is going to be a series of books that tells the
                overarching story set in the background of geopolitical and
                global conflict between GDA and UFN in an imaginative and
                futuristic world of 2105.
              </p>

              <p className="normalcontent pb-4">
                Unimaginable wars happening across the globe are because of the
                hatred incited by dark forces. But, hope in humanity is still
                alive because a young boy, through his journey has discovered
                the supernatural forces of light and darkness hidden in the
                Modern world. Forces that are beyond humans that are greatly
                impacting the good life on Earth; the Creator has given to
                humans.
              </p>
              <p className="normalcontent pb-4">
                Angelo realises his part in the prophecy foretelling the end of
                the world.
              </p>
              <p className="normalcontent pb-4">
                Join us in this action and adventure filled Graphic Novel and
                walk through his emotions like hopelessness, loss and finding
                new hope and abilities by confronting demons and celestials of
                other metaphysical realms.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section
        id="characters"
        className="min-vh-100 d-flex align-items-center text-center"
      >
      </section>

      <div className="container" id="lead">
        <div className="row">
          <div className="col text-center">
            <p className="chartitles pt-2">LEAD CHARACTERS</p>
            <p className="bigcontent">
              CAST AND <br className="d-md-block d-none"></br> CREW
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div className="row">
              <div className="col pt-3 pb-5">
                <div className="row justify-content-center">
                  <div className="col-md-4 col-lg-3 col-6">
                    <div className="mb-3">
                      <img
                        src={character1}
                        className="img-fluid w-100"
                        alt=""
                      />
                      <p className="charname text-center pt-2">ANGELO</p>
                    </div>
                  </div>

                  <div className="col-md-4 col-lg-3 col-6">
                    <div className="mb-3">
                      <img
                        src={character2}
                        className="img-fluid w-100"
                        alt=""
                      />
                      <p className="charname text-center pt-2">DIANA</p>
                    </div>
                  </div>

                  <div className="col-md-4 col-lg-3 col-6">
                    <div className="mb-3">
                      <img
                        src={character3}
                        className="img-fluid w-100"
                        alt=""
                      />
                      <p className="charname text-center pt-2">COMMANDER</p>
                    </div>
                  </div>

                  <div className="col-md-4 col-lg-3 col-6">
                    <div className="mb-3">
                      <img
                        src={character4}
                        className="img-fluid w-100"
                        alt=""
                      />
                      <p className="charname text-center pt-2">MAGOTH</p>
                    </div>
                  </div>

                  {/* <div className="col-md-4 col-lg-3 col-6">
                    <div className="mb-3">
                      <img
                        src={character5}
                        className="img-fluid w-100"
                        alt=""
                      />
                      <p className="charname text-center pt-2">GOL</p>
                    </div>
                  </div>

                  <div className="col-md-4 col-lg-3 col-6">
                    <div className="mb-3">
                      <img
                        src={character6}
                        className="img-fluid w-100"
                        alt=""
                      />
                      <p className="charname text-center pt-2">DEMON</p>
                    </div>
                  </div>

                  <div className="col-md-4 col-lg-3 col-6">
                    <div className="mb-3">
                      <img
                        src={character7}
                        className="img-fluid w-100"
                        alt=""
                      />
                      <p className="charname text-center pt-2">GDA SOLDIER</p>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col px-0">
            <div className="scrollcontent d-md-block d-none">
              <img
                src={scrollimg}
                alt="endwar scroll"
                className="w-100"
              ></img>
            </div>

            <div className="d-md-none d-block">
              <img
                src={scrollimg}
                alt="endwar scroll"
                className="img-fluid"
              ></img>
            </div>
          </div>
        </div>
      </div>

      <section id="preview" className="d-flex align-items-center text-center">

        {/* <div className="container">
          <div className="row">
            <div
              className="col-12 text-center"
              data-aos="fade-down"
              data-aos-delay="50"
            >
              <div className="section-title">
                <p>PREVIEW</p>
                <h1 className="display-2 fw-bolder section-title-text">
                  AT VERO EOS ET ACCUSAM
                </h1>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center mx-auto">
            <div className="me-2">
              <img src={Preview1} alt="" />
            </div>
            <div className="me-2">
              <img src={Preview2} alt="" />
            </div>
            <div className="me-2">
              <img src={Preview1} alt="" />
            </div>
            <div className="me-2">
              <img src={Preview2} alt="" />
            </div>
          </div>
          <div className="text-center mt-3 pb-5">
            <button className="sampleBtn">SAMPLE PREVIEW</button>
          </div>
        </div> */}

        <div className="container">
          <div className="row">
            <div className="col text-center">
              <p className="chartitles pt-5">PREVIEW</p>
              <p className="bigcontent">
                DIVE IN TO <br className="d-md-block d-none"></br> KNOW THE
                DEPTH
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <div className="row">
                <div className="col pt-3 pb-5">
                  <div className="row justify-content-center">

                    <div className="col-md-6 col-lg-3 col-7">
                      <div className="mb-3">
                        <img
                          src={Pre1}
                          className="img-fluid imagewidth"
                          alt=""
                        />
                      </div>
                    </div>

                    {/* <div className="col-md-6 col-lg-3 col-7">
                      <div className="mb-3">
                        <img
                          src={Preview2}
                          className="img-fluid imagewidth"
                          alt=""
                        />
                      </div>
                    </div> */}

                    <div className="col-md-6 col-lg-3 col-7">
                      <div className="mb-3">
                        <img
                          src={Preview1}
                          className="img-fluid imagewidth"
                          alt=""
                        />
                      </div>
                    </div>

                    <div className="col-md-6 col-lg-3 col-7">
                      <div className="mb-3">
                        <img
                          src={Pre3}
                          className="img-fluid imagewidth"
                          alt=""
                        />
                      </div>
                    </div>

                    <div className="col-md-6 col-lg-3 col-12">
                      <div className="mb-3">
                        <img
                          src={Pre4}
                          className="img-fluid imagewidth"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>

                  {/* <button className="buybtn px-4 py-2 mt-4">
                    SAMPLE PREVIEW
                  </button> */}

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-preview min-vh-100 d-flex align-items-center text-center my-3">
      </section>

      <section id="author" className="d-flex align-items-center text-center">
        <div className="container">
          {/* <div className="row">
            <div
              className="col-12 text-center"
              data-aos="fade-down"
              data-aos-delay="50"
            >
              <div className="section-title">
                <p>THE AUTHOR</p>
                <h1 className="display-2 fw-bolder section-title-text">
                  DIAM NONUMY EIRMOD TEMPOR INVIDUNT UT
                </h1>
              </div>
            </div>
          </div> */}

          <div className="row">
            <div className="col text-center">
              <p className="chartitles pt-3">THE AUTHOR</p>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-author min-vh-100 d-flex align-items-center text-center">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              {/* <img src={Preview1} alt="dd" /> */}
              <p className="bigcontent">
                MAKING EVERY <br className="d-md-block d-none"></br> WORD COUNT
              </p>
              {/* <p className="charname pt-3">AMIRTHARAJ SELVARAJ</p> */}
            </div>
          </div>

          <div className="row">
            <div className="col-md-9 col-xl-6 col-12 mx-auto">
              <p className="normalcontent text-start">
                {" "}
                Amirtharaj Selvaraj is the Director of Rajam Group of companies
                and is the Member of the Legislative Assembly. He completed his
                schooling from Don Bosco, Egmore, Chennai, his graduation is
                from Sri Venkateshwara College of Engineering and he has an MBA
                from Cardiff University. As an engineer turned politician, He
                held the post of State General Secretary of Tamilnadu Youth
                Congress , now he is an All India Congress Committee Member and
                South Tuticorin district Congress President. Recognising his
                service to public he was given a chance to contest in 2021 State
                General Elections, in which he won and became a Member of
                Legislative Assembly from his native Srivaikundam Constituency,
                Tuticorin District.
              </p>
              <p className="normalcontent text-start pb-4">
                <span className="authdesc">
                  Now where does a Graphic Novel come into picture?
                </span>
                <br></br> With an ardent passion for comics and storytelling, he
                has now turned to publish his debut Coming of Age, Graphic Novel
                that focuses on the protagonist’s psychological turmoil in a
                fictional world. Furthermore, he started Queensland Studios to
                connect with teenagers, excite them with compelling story,
                visual media and to promote innovative excellence in young
                minds.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="d-flex align-items-center text-center mt-5 pt-5">
        <div className="container pt-md-5 mt-md-5">
          <div className="imagerel">
            <div className="row pt-md-5 mt-md-5">
              <div className="col-md-12 mx-auto pt-5 mt-md-5">
                <div className="footerBg pb-5 pt-lg-5 my-lg-5">
                  <div className="pt-5 mt-md-5">
                    <div className="pt-5 mt-md-5">
                      <div className="pt-5 pt-lg-0">
                        <div className="mobilemargin">
                          <img
                            src={title}
                            alt=""
                            className="img-fluid pt-5 pt-md-0 mt-4 mt-md-0"
                          />
                        </div>
                      </div>
                    </div>
                  </div>{" "}
                  <div className="row">
                    <div className="col-12">
                      <a
                        href="https://docs.google.com/forms/d/e/1FAIpQLSeegJIw0VzNf8g7ZXssFqudn4Hya8FeIbZRjQuOOQXuSuqKdA/viewform"
                        target={"_blank"}
                      >
                        <button className="buybtn mt-5 mt-md-0 me-2">
                          SUBMIT FEEDBACK
                        </button>
                      </a>
                      <a
                        href="https://docs.google.com/forms/d/e/1FAIpQLSdyzTFlzzcsOqFvhuIIHlcSAsYWQ0j8dLcDhOUJ8un30HA94g/viewform"
                        target={"_blank"}
                      >
                        <button className="buybtn mt-3 mt-md-0">
                          ORDER NOW
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="imageabs">
              <div className="row">
                <div className="col-md-12 mx-auto">
                  <img src={Comic} alt="" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* <section className="d-flex align-items-center text-center mt-5 pt-5">
        <div className="container pt-md-5 mt-md-5">
          <div className="imagerel">
            <div className="row pt-md-5 mt-md-5">
              <div className="col-md-12 mx-auto pt-5 mt-md-5">
                <div className="footerBg pb-5 pt-lg-5 my-lg-5">
                  <div className="pt-5 mt-md-5">
                    <div className="pt-5">
                      <div className="">
                        <div className="mobilemargin">
                          <p className="v2head pt-5 pt-md-0">END WARS</p>
                          <p className="chartitles">VOL 2: THE DARK CONQUEST</p>
                        </div>
                      </div>
                    </div>
                  </div>{" "}
                  <button className="comingsoonbtn px-4 py-2 mb-md-5">
                    COMING SOON
                  </button>
                </div>
              </div>
            </div>

            <div className="imageabs">
              <div className="row">
                <div className="col-md-12 mx-auto">
                  <img src={v2} alt="" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section className="bg py-5">

        <div className="container">
          <div className="row">
            <div className="col text-center">
              <p className="chartitles">PROJECTS BY</p>
              <img src={queensland} alt="" className="img-fluid" />
            </div>
          </div>
        </div>

        <section className="d-flex align-items-center text-center">
          <div className="container pt-5 mt-5">
            <div className="row">

              <div className="col-12 col-md-6 col-xl-3 mt-4" id="col4">
                <div className="imagerel">
                  <div className="row pt-5">
                    <div className="col-md-12 mx-auto">
                      <div className="footerBg p-4">
                        <p className="cont pt-5 mb-0">Endwars - Volume 3</p>
                        <p className="charname mb-0">ENGLISH</p>
                        <p className="mb-0 mrpsize"><span>M.R.P</span> <span className="amountdiscount">Rs.1150</span></p>
                        <p className=" mb-0 "><span className="cont">Offer Price: Rs.599</span> </p>
                        <p><span className="gstsize">( including GST & delivery charges )</span></p>
                      </div>
                    </div>
                  </div>
                  <div className="imageabs">
                    <div className="row">
                      <div className="col-md-12 mx-auto">
                        <img src={v6} alt="" className="img-fluid" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6 col-xl-3 mt-4" id="col1">
                <div className="imagerel">
                  <div className="row pt-5">
                    <div className="col-md-12 mx-auto">
                      <div className="footerBg p-4">
                        <p className="cont pt-5 mb-0">Endwars - Volume 2</p>
                        <p className="charname mb-0">ENGLISH</p>
                        <p className="mb-0 mrpsize"><span>M.R.P</span> <span className="amountdiscount">Rs.1250</span></p>
                        <p className=" mb-0 "><span className="cont">Offer Price: Rs.599</span> </p>
                        <p><span className="gstsize">( including GST & delivery charges )</span></p>
                      </div>
                    </div>
                  </div>
                  <div className="imageabs">
                    <div className="row">
                      <div className="col-md-12 mx-auto">
                        <img src={v2} alt="" className="img-fluid" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6 col-xl-3 mt-4" id="col2">
                <div className="imagerel">
                  <div className="row pt-5">
                    <div className="col-md-12 mx-auto">
                      <div className="footerBg p-4">
                        <p className="cont pt-5 mb-0">Endwars - Volume 1</p>
                        <p className="charname mb-0">ENGLISH</p>
                        <p className="mb-0 mrpsize"><span>M.R.P</span> <span className="amountdiscount">Rs.1250</span></p>
                        <p className=" mb-0 "><span className="cont">Offer Price: Rs.599</span> </p>
                        <p><span className="gstsize">( including GST & delivery charges )</span></p>
                      </div>
                    </div>
                  </div>
                  <div className="imageabs">
                    <div className="row">
                      <div className="col-md-12 mx-auto">
                        <img src={v3} alt="" className="img-fluid" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6 col-xl-3 mt-4" id="col3">
                <div className="imagerel">
                  <div className="row pt-5">
                    <div className="col-md-12 mx-auto">
                      <div className="footerBg p-4">
                        <p className="cont pt-5 mb-0">Endwars - Volume 1</p>
                        <p className="charname mb-0">TAMIL</p>

                        <p className="mb-0 mrpsize"><span>M.R.P</span> <span className="amountdiscount">Rs.1250</span></p>
                        <p className=" mb-0 "><span className="cont">Offer Price: Rs.399</span> </p>
                        <p><span className="gstsize">( including GST & delivery charges )</span></p>
                      </div>
                    </div>
                  </div>
                  <div className="imageabs">
                    <div className="row">
                      <div className="col-md-12 mx-auto">
                        <img src={v4} alt="" className="img-fluid" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <div className="row mx-1 mx-md-0">

              <div className="col-12 col-xl-6 mt-4 mt-xl-3 p-xl-4">
                <div className="row footerBg p-4">

                  <div className="col-12 col-md-6">
                    <img src={v5} className="img-fluid" alt="Endwars" />
                  </div>

                  <div className="col-12 col-md-6">
                    <div className="pt-4 pt-md-0 pt-xl-3">
                      <p className="cont mb-0 ">Endwars</p>
                      <p className="charname mb-0">ENGLISH</p>
                      <p className="mb-0 "><span className="cont">Buy any 2 @ <br /> Rs. 999</span> </p>
                      <p className="mb-0 "><span className="gstsize">( including GST & delivery charges )</span></p>
                    </div>
                  </div>

                </div>
              </div>

              <div className="col-12 col-xl-6 mt-4 mt-xl-3 p-xl-4">
                <div className="row footerBg p-4">

                  <div className="col-12 col-md-6">
                    <img src={v7} className="img-fluid" alt="Endwars" />
                  </div>

                  <div className="col-12 col-md-6">
                    <div className="pt-4 pt-md-0 pt-xl-3">
                      <p className="cont mb-0 ">Endwars</p>
                      <p className="charname mb-0">ENGLISH</p>
                      <p className="mb-0 "><span className="cont">Buy 3 @ <br /> Rs. 1299</span> </p>
                      <p className="mb-0 "><span className="gstsize">( including GST & delivery charges )</span></p>
                    </div>
                  </div>

                </div>
              </div>


            </div>

          </div>
        </section>

        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <div className="row">
                <div className="col-12">
                  <a
                    href="https://forms.gle/rniwRW9ZtwHkgiix7"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <button className="buybtn mt-5 me-2">
                      SUBMIT FEEDBACK
                    </button>
                  </a>
                  <a
                    href="https://forms.gle/Q41pSvcAo2tSUwmS6"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <button className="buybtn mt-3">ORDER NOW</button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

      </section>

      <div className="py-5">
        <p className="normalcontent text-center mb-0">
          End Wars © 2024. All rights reserved.
        </p>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col text-end scrollbottom">
            <a href="#hero">
              <img src={scroll} alt="scrollimg" className="img-fluid" />
            </a>
          </div>
        </div>
      </div>

    </>
  );
};
